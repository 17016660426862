import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const StorybookEmbed = makeShortcode("StorybookEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Shorthand`}</h2>
    <p>{`Since padding utilities have many variations and will be used in many places, we use a shorthand naming convention to help keep class names succinct.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Shorthand`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`p`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`t`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`top`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`r`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`right`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`b`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bottom`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`l`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`left`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`x`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`horizontal, left & right`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`vertical, top & bottom`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`112px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`128px`}</td>
        </tr>
      </tbody>
    </table>
    <p><em parentName="p"><strong parentName="em">{`Note:`}</strong>{` The gray in the examples below represents the element, and the yellow represents the padding.`}</em></p>
    <h2>{`Uniform padding`}</h2>
    <p>{`Use uniform spacing utilities to apply equal padding to all sides of an element. These utilities can be used with a spacing scale from 0-6.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-padding--uniform'
    }]} height="150" mdxType="StorybookEmbed" />
    <h2>{`Directional padding`}</h2>
    <p>{`Use directional utilities to apply padding to an individual side, or the X and Y axis of an element. Directional utilities can change or override default padding, and can be used with a spacing scale of 0-6.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-padding--directional'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`Extended directional padding`}</h2>
    <p>{`The extended directional padding scale starts from spacer `}<inlineCode parentName="p">{`7`}</inlineCode>{` and goes up to `}<inlineCode parentName="p">{`12`}</inlineCode>{`. All directions and their responsive variants are supported, except for `}<inlineCode parentName="p">{`px`}</inlineCode>{`.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-padding--directional-extended'
    }]} height="200" mdxType="StorybookEmbed" />
    <h2>{`Responsive padding`}</h2>
    <p>{`All padding utilities can be adjusted per `}<a parentName="p" {...{
        "href": "/foundations/css-utilities/grid#breakpoints"
      }}>{`breakpoint`}</a>{` using the following formula: `}<br />{` `}<inlineCode parentName="p">{`p-[direction]-[breakpoint]-[spacer]`}</inlineCode>{`. Each responsive style is applied to the specified breakpoint and up.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-padding--responsive'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`Responsive container padding`}</h2>
    <p><inlineCode parentName="p">{`.p-responsive`}</inlineCode>{` is a padding class that adds padding on the left and right sides of an element. It is intended to be used with `}<a parentName="p" {...{
        "href": "/foundations/css-utilities/grid#containers"
      }}>{`container styles`}</a>{`.`}</p>
    <ul>
      <li parentName="ul">{`On `}<strong parentName="li">{`0 to sm`}</strong>{` screens, it gives the element padding of `}<inlineCode parentName="li">{`$spacer-3`}</inlineCode></li>
      <li parentName="ul">{`On `}<strong parentName="li">{`sm to lg`}</strong>{` screens it gives the element padding of `}<inlineCode parentName="li">{`$spacer-6`}</inlineCode></li>
      <li parentName="ul">{`On `}<strong parentName="li">{`lg and wider`}</strong>{` screens, it gives the element padding of `}<inlineCode parentName="li">{`$spacer-3`}</inlineCode></li>
    </ul>
    <p>{`It's the equivalent of adding the `}<inlineCode parentName="p">{`.px-3 .px-sm-6 .px-lg-3`}</inlineCode>{` utility classes.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-padding--responsive-container'
    }]} height="100" mdxType="StorybookEmbed" />
    <blockquote>
      <p parentName="blockquote">{`Note: Since the example is shown in an `}<inlineCode parentName="p">{`iframe`}</inlineCode>{` it won't show the change to `}<inlineCode parentName="p">{`$spacer-3`}</inlineCode>{` for the `}<inlineCode parentName="p">{`lg`}</inlineCode>{` breakpoint.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      